import React, { useEffect, useState } from 'react';
import DownloadIcon from '../../assets/svgIcons/DownloadIcon';
import Header from '../Header/component';
import './styles.scss';
import { getInvoiceFile, getPaymentHistoy } from './billingApi';
import { get } from 'lodash';
import Loader from 'components/loader/loader';
import { cancelSubscription } from './billingApi';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button as AntButton } from 'antd';
import { stripeCheckout } from '../three-steps/accountApi';
import { useDispatch, useSelector } from 'react-redux';
// import ChatbotWeb from '../../components/chatbot/ChatbotWeb';
// import ChatbotMobile from 'components/chatbot/ChatbotMobile';
import { getTradingPlan } from '../../components/chatbot/tradingPlanSlice';
import {
  selectUserDetails,
  getPaymentHistory,
  setPaymentHistory,
  setUserdetails,
  updateAccounts,
  selectAccounts,
  getDefaultAccount
} from 'features/login/loginSlice';
import { Popconfirm, Tooltip } from 'antd';
import { getUserDetails } from 'features/profile-screen/userApi';

const Billing = () => {
  const DefaultAccount = useSelector(getDefaultAccount);
  const user = useSelector(selectUserDetails);
  const billingData = useSelector(getPaymentHistory);
  const dispatch = useDispatch();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { currentAccount } = useSelector((state) => state.chatbot);
  const [fileDownloads, setFileDownloads] = useState({});
  const [paymentModal, setPaymentModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('monthly');
  const [accountId, setAccountId] = useState('');
  const accounts = useSelector(selectAccounts);
  const [selectedLogin, setSelectedLogin] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');
  const Helper = require('../../utils/helper');
  const token = localStorage.getItem('jwtToken');
  const activeAccounts = accounts?.filter(
    (account) => account.status === 'active'
  );

  const title =
    'Deactivating your subscription will stop auto-renewal. You will not be charged next month.';

  const PausedMsg =
    'Your current plan has been deactivated. Once it expires, you will be able to resubscribe to a new plan.';

  function titleCase(string) {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  }

  useEffect(() => {
    Helper.isTokenExpired(token);
  }, []);

  useEffect(() => {
    if (currentAccount !== '') {
      dispatch(getTradingPlan(currentAccount));
    }
  }, [currentAccount, dispatch]);

  useEffect(() => {
    if (DefaultAccount) {
      setSelectedAccount(DefaultAccount);
      const selectedAcc =
        activeAccounts &&
        activeAccounts.find((account) => account.account_id === DefaultAccount);
      if (selectedAcc) {
        setSelectedLogin(selectedAcc.login);
      }
    }
  }, [DefaultAccount, activeAccounts]);

  useEffect(() => {
    if (user.user === undefined) {
      fetchUser();
    }
  }, []);

  const fetchUser = async () => {
    try {
      const data = await getUserDetails();
      dispatch(setUserdetails(data));
      dispatch(updateAccounts(data?.accounts || []));
    } catch (error) {}
  };

  const fetchPaymentHistoy = async () => {
    try {
      const data = await getPaymentHistoy();
      dispatch(setPaymentHistory(data));
    } catch (error) {
      console.log('error==>', error);
    }
  };

  useEffect(() => {
    fetchPaymentHistoy();
  }, []);

  const activeSubscription = async (account_id) => {
    setAccountId(account_id);
    setPaymentModal(true);
  };

  const deactivateSubscription = async (
    subscription_id,
    account_id,
    subscription_status
  ) => {
    setAccountId(account_id);
    try {
      setConfirmLoading(true);
      await cancelSubscription(
        subscription_id,
        subscription_status,
        account_id
      );
      fetchPaymentHistoy();
      setConfirmLoading(false);
    } catch (error) {
      console.log('cancel subscription error', error);
      setConfirmLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const AccountComponent = () => {
    const account_history = get(billingData, 'account_history', []);
    return (
      <div className="table-responsive table-wrapper">
        <table className="table m-0">
          <thead>
            <tr>
              <th>Date</th>
              <th>Account #</th>
              <th>Billing Type</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {account_history &&
              account_history.map((data) => {
                const formattedDate = formatDate(data.timestamp);
                const subscription =
                  data?.subscription && data?.subscription.toLowerCase();
                return (
                  <tr key={data.account_id}>
                    <td>{formattedDate}</td>
                    <td>{data.account_id}</td>
                    <td>{data.subscription}</td>
                    <td>
                      <div
                        className={
                          data.subscription_status !== 'active'
                            ? 'unpaid'
                            : 'paid'
                        }
                      >
                        {data.subscription_status === 'active'
                          ? 'Active'
                          : data.subscription_status === 'paused'
                          ? 'Paused'
                          : 'Inactive'}
                      </div>
                    </td>

                    <td>
                      {data.subscription_status === 'active' ? (
                        <Popconfirm
                          title="Deactivate your subscription"
                          description={`Are you sure to deactivate your ${subscription} subscription`}
                          onConfirm={() =>
                            deactivateSubscription(
                              data.subscription_id,
                              data.account_id,
                              data.subscription_status
                            )
                          }
                          okButtonProps={{
                            loading: confirmLoading
                          }}
                          showCancel={true}
                        >
                          <Tooltip placement="top" title={title}>
                            <button className={'red-deactive'}>
                              {'Deactivate'}
                            </button>
                          </Tooltip>
                        </Popconfirm>
                      ) : data.subscription_status === 'paused' ? (
                        <Tooltip placement="top" title={PausedMsg}>
                          <button className={'gray-paused'} disabled>
                            {'Resubscribe'}
                          </button>
                        </Tooltip>
                      ) : (
                        <button
                          className={'blue-active'}
                          onClick={() => activeSubscription(data.account_id)}
                        >
                          {'Resubscribe'}
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  };

  const PaymentComponent = () => {
    const payment_history = get(billingData, 'payment_history', []);
    const handleButtonClick = async (index) => {
      const invoiceId = payment_history[index]?.invoice;
      if (invoiceId) {
        try {
          setFileDownloads({ ...fileDownloads, [invoiceId]: true });
          const res = await getInvoiceFile(invoiceId);
          if (res) {
            window.open(res?.receipt_url);
            // window.location.href = res?.receipt_url;
            setFileDownloads({ ...fileDownloads, [invoiceId]: false });
          }
        } catch (error) {
          setFileDownloads({ ...fileDownloads, [invoiceId]: false });
        }
      }
    };
    return (
      <div className="table-responsive table-wrapper">
        <table className="table m-0">
          <thead>
            <tr>
              <th>Date</th>
              <th>Transaction Code</th>
              <th>Account #</th>
              <th>Amount</th>
              <th>Method</th>
              <th>Status</th>
              <th>Invoice</th>
            </tr>
          </thead>
          <tbody>
            {payment_history.length < 1 && (
              <tr>
                <td colSpan={12} className="text-center border-0">
                  No Payment History Available
                </td>
              </tr>
            )}
            {payment_history &&
              payment_history.map((data, index) => {
                const amount = data.amount_paid / 100;
                const formattedDate = formatDate(data.timestamp);
                const invoiceId = data.invoice;
                const isLoading = fileDownloads[invoiceId];
                const method = get(data, 'payment_method_details.type', '');
                const brand = get(
                  data,
                  'payment_method_details.card.brand',
                  ''
                );
                const last4 = get(
                  data,
                  'payment_method_details.card.last4',
                  ''
                );
                const brandname = brand && titleCase(brand);

                return (
                  <tr key={data.transaction_id}>
                    <td>{formattedDate}</td>
                    <td>{data.transaction_id}</td>
                    <td>{data.account_id}</td>
                    <td>{`$${amount}`}</td>
                    <td>
                      {method === 'link' ? 'Link' : `${brandname} *${last4} `}
                    </td>
                    <td>
                      <span
                        className={data.status === 'failed' ? 'unpaid' : 'paid'}
                      >
                        {data.status === 'failed' ? 'Failed' : 'Paid'}
                      </span>
                    </td>
                    <td>
                      <AntButton
                        className={
                          data.status !== 'completed' ? 'disabled-button' : ''
                        }
                        disabled={data.status === 'failed'}
                        type="success"
                        style={{
                          borderWidth: 0,
                          backgroundColor: 'white',
                          fill: data.status === 'failed' ? 'gray' : '#000000'
                        }}
                        key={index}
                        loading={isLoading}
                        icon={<DownloadIcon />}
                        onClick={() => handleButtonClick(index)}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  };

  const handleRadioChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  const toggle = () => setPaymentModal(false);

  const _subscription = async () => {
    setPaymentModal(false);
    try {
      const plan = selectedPlan.toLowerCase();
      const customer_id = user?.user?.stripe_customer_id;
      const resubscribe = true
      await stripeCheckout(plan, accountId, customer_id, resubscribe);
    } catch (error) {
      console.error('error subscription:', error);
      throw error;
    }
  };

  return (
    <>
      <div className='header-full-width'>
      <Header />
      </div>
      <div className="main_page">
        <div
          className="left_container"
          style={{ position: 'relative', minHeight: '100vh', width:'100%' }}
        >
          <div className="main--container">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-12 col-lg-12 p-0">
                  <div className="table-container">
                    <div className="table-sub-container">
                      <div className="main-heading">
                        <p>Billing</p>
                      </div>
                      {Object.keys(billingData).length === 0 ? (
                        <Loader />
                      ) : (
                        <div className="Billing-component">
                          <h2 className="table-heading">Accounts</h2>
                          <AccountComponent />
                          <h2 className="table-heading mt-5">
                            Payment History
                          </h2>
                          <PaymentComponent />
                        </div>
                      )}
                      {/* <ChatbotMobile
                        selectedLogin={selectedLogin}
                        activeAccounts={activeAccounts}
                      /> */}

                      {/* <TradeTable
                    accountNo={selectedAccount}
                    appliedFilters={filters}
                  /> */}
                    </div>
                  </div>
                  {/* <button
                    className="chat--icon"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                  >
                    <img alt="chatIcon" src={chatIcon} />
                  </button>
                  <div
                    class="offcanvas offcanvas-end"
                    tabindex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                  >
                    <div class="offcanvas-header">
                      <button
                        type="button"
                        class="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="offcanvas-body">...</div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="right_container">
          <ChatbotWeb
            from={'account'}
            selectedLogin={selectedLogin}
            activeAccounts={activeAccounts}
          />
        </div> */}
      </div>
      <Modal
        isOpen={paymentModal}
        toggle={toggle}
        centered
        className="new-account-modal"
      >
        <ModalHeader toggle={toggle}>Account # {accountId}</ModalHeader>
        <ModalBody>
          <h1>
            <span className="rate">
              {selectedPlan === 'monthly' ? '$49' : '$509'}
            </span>
            <span className="monthly">
              {selectedPlan === 'monthly' ? '.95 monthly' : '.49 yearly'}
            </span>
          </h1>
          <div className="monthly-yearly-toggle-block">
            <div class="pricing-switcher">
              <div className="fieldset">
                <input
                  type="radio"
                  name="group-a"
                  value="monthly"
                  id="monthly-1"
                  checked={selectedPlan === 'monthly'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="monthly-1">Monthly</label>
                <input
                  type="radio"
                  name="group-a"
                  value="yearly"
                  id="yearly-1"
                  checked={selectedPlan === 'yearly'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="yearly-1">Yearly</label>
                <span className="switch"></span>
              </div>
            </div>
            <span className="yellowText">Save 15%</span>
          </div>
          <ul className="plans-list">
            <li>Ai Trading Analysis</li>
            <li>Personalized Trading Plan</li>
            <li>Real-time AI Chat</li>
            <li>Live Consistency Score</li>
            <li>Multi-Account Management</li>
            <li>Dedicated Trade Analysis</li>
            <li>Journal and Insights</li>
            <li>Secure Data Handling</li>
          </ul>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button
            className="btn-sm w-25"
            color="primary"
            onClick={_subscription}
          >
            Next
          </Button>
          <span className="footer-title">
            Cancel your subscription at any time without any hassle.
          </span>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Billing;
