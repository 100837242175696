import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Header/component';
import { useParams } from 'react-router-dom';
import './styles.scss';
// import ChatbotWeb from '../../components/chatbot/ChatbotWeb';
import { selectAccounts, getDefaultAccount, setUserdetails, updateAccounts } from 'features/login/loginSlice';
import { getTradingPlan } from '../../components/chatbot/tradingPlanSlice';
import { getUserDetails } from 'features/profile-screen/userApi';
// import ChatbotMobile from 'components/chatbot/ChatbotMobile';

const HelpDesk = (props) => {
  const dispatch = useDispatch();
  const DefaultAccount = useSelector(getDefaultAccount);
  const [selectedLogin, setSelectedLogin] = useState('');
  const [selectedAccount, setSelectedAccount] = useState(DefaultAccount ? DefaultAccount : null);
  const { currentAccount } = useSelector((state) => state.chatbot);
  const allAccounts = useSelector(selectAccounts);
  let { accountId } = useParams();
  const accounts = allAccounts?.filter((account) => account.status === 'active');
  const user = useSelector((state) => state.login.user);
  const Helper = require('../../utils/helper');
  const token = localStorage.getItem('jwtToken');


  useEffect(() => {
    if (currentAccount !== '') {
      dispatch(getTradingPlan(currentAccount));
    }
  }, [currentAccount, dispatch]);



  useEffect(() => {
    Helper.isTokenExpired(token);
  }, []);
  
  useEffect(() => {
    const accountId = DefaultAccount ?? accounts[0]?.account_id;
    const selected_account = accounts.find((account) => account.account_id === accountId);
    if (selected_account) {
      setSelectedLogin(selected_account.login);
    }
    setSelectedAccount('');
  }, [DefaultAccount, accountId, accounts, selectedAccount]);

  // Fetch user details if not already available
  useEffect(() => {
    if (!user) {
      fetchUser();
    }
  }, [user]);

  const fetchUser = async () => {
    try {
      const data = await getUserDetails();
      dispatch(setUserdetails(data));
      dispatch(updateAccounts(data?.accounts || []));
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  return (
    <>
      <div className="main_page">
        <div className="left_container" style={{width:'100%'}}>
          <div className="main--container">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-12 col-lg-12 p-0">
                  <div className='header-full-width'>
                  <Header />
                  </div>
                  <div className="table-container">
                    <div className="table-sub-container">
                      <div className="main-heading">
                        <p>Help Desk</p>
                      </div>
                      <div className="help-desk-container">
                        <iframe
                          title="Feedback Form"
                          class="freshwidget-embedded-form"
                          id="freshwidget-embedded-form"
                          src="https://fractalalpha.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&formTitle=Help+%26+Support&submitTitle=Submit&submitThanks=Thank+you+for+contacting+FractalAlpha%2C+one+of+our+representatives+will+respond+shortly.&locale=en"
                          scrolling="no"
                          height="900px"
                          width="100%"
                          frameborder="0"
                        ></iframe>
                      </div>
                      <script
                        type="text/javascript"
                        src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"
                      ></script>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div>
            <ChatbotMobile 
             selectedLogin={selectedLogin}
             activeAccounts={accounts}
             />
          </div> */}
        </div>
        {/* <div className="right_container">
          <ChatbotWeb
            from={'account'}
            selectedLogin={selectedLogin}
            activeAccounts={accounts}
          />
        </div> */}
      </div>
    </>
  );
};

export default HelpDesk;

// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import Header from '../Header/component';
// import { useParams } from 'react-router-dom';
// import './styles.scss';
// import ChatbotWeb from '../../components/chatbot/ChatbotWeb';
// import { selectAccounts, getDefaultAccount } from 'features/login/loginSlice';
// import { getTradingPlan } from '../../components/chatbot/tradingPlanSlice';

// const HelpDesk = (props) => {
//   const dispatch = useDispatch();
//   const DefaultAccount = useSelector(getDefaultAccount);
//   const [selectedLogin, setSelectedLogin] = useState('');
//   const [selectedAccount, setSelectedAccount] = useState(
//     DefaultAccount ? DefaultAccount : null
//   );
//   const { currentAccount } = useSelector((state) => state.chatbot);
//   const allAccounts = useSelector(selectAccounts);
//   let { accountId } = useParams();
//   const accounts = allAccounts?.filter(
//     (account) => account.status === 'active'
//   );

//   useEffect(() => {
//     if (currentAccount !== '') {
//       dispatch(getTradingPlan(currentAccount));
//     }
//   }, [currentAccount, dispatch]);

//   useEffect(() => {
//     const accountId = DefaultAccount ?? accounts[0]?.account_id;
//     const selected_account = accounts.find(
//       (account) => account.account_id === accountId
//     );
//     if (selected_account) {
//       setSelectedLogin(selected_account.login);
//     }
//   }, [DefaultAccount, accountId, accounts, selectedAccount]);

//   return (
//     <>
//       <div className="main_page">
//         <div className="left_container">
//           <div className="main--container">
//             <div className="container-fluid">
//               <div className="row">
//                 <div className="col-xl-12 col-lg-12 p-0">
//                   <Header />
//                   <div className="table-container">
//                     <div className="table-sub-container">
//                       <div className="main-heading">
//                         <p>Help Desk</p>
//                       </div>
//                       <div className="help-desk-container">
//                         <iframe
//                           title="Feedbac k Form"
//                           class="freshwidget-embedded-form"
//                           id="freshwidget-embedded-form"
//                           src="https://fractalalpha.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&formTitle=Help+%26+Support&submitTitle=Submit&submitThanks=Thank+you+for+contacting+FractalAlpha%2C+one+of+our+representatives+will+respond+shortly."
//                           scrolling="no"
//                           height="900px"
//                           width="100%"
//                           frameborder="100%"
//                         ></iframe>
//                       </div>
//                       <script
//                         type="text/javascript"
//                         src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"
//                       ></script>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             {/* <ChatbotMobile /> */}
//           </div>
//         </div>
//         <div className="right_container">
//           <ChatbotWeb
//             from={'account'}
//             selectedLogin={selectedLogin}
//             activeAccounts={accounts}
//           />
//         </div>
//       </div>
//     </>
//   );
// };

// export default HelpDesk;
