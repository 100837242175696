import axios from 'axios';
import AppConfig from '../../config';
import { getAnalysisReport } from 'features/fa-analysis/analysisApi';

export const addNewAccount = async (loginDetails, account_id) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      AppConfig.apiUrl + `/create-account?account_id=${account_id}`,
      loginDetails,
      {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json'
        }
      }
    );

    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message;

    console.error('Error during sign in:', error);

    throw errorMessage;
  }
};

export const authenticateMetaTraderAc = async (login_id) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.get(
      AppConfig.apiUrl + `/check_user?login=${login_id}`,
      {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message;

    console.error('Error during sign in:', error);

    throw errorMessage;
  }
};

export const initiateAnalysis = async (account_id) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      `${AppConfig.apiUrl}/generate_report`,
      { account_id },
      {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json'
        }
      }
    );

    return response.data;
  } catch (error) {
    if (error.message === 'Network Error') {
      // Return a Promise that resolves to the result of getAnalysisReport
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            const res = await getAnalysisReport(account_id);
            resolve(res);
          } catch (error) {
            reject(error);
          }
        }, 15000);
      });
    } else {
      // Handle other errors here if needed
      return error; // Rethrow the error if it's not a Network Error
    }
  }
};

export const getNumberOftrade = async (login_id) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.get(
      AppConfig.apiUrl + `/count_trades?login=${login_id}`,
      {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    console.error('Error during sign in:', error);
    throw error.response?.data;
  }
};

export const stripeCheckout = async (
  plan,
  accountId,
  customer_id,
  resubscribe
) => {
  console.log('resubscribe===>>', resubscribe)
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      `${AppConfig.apiUrl}/create-checkout-session`,
      {
        subscription: plan,
        customer_id: customer_id,
        account_id: accountId,
        resubscribe: resubscribe
      },
      {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json'
        }
      }
    );
    const url = response.data.url;
    window.location.replace(url);
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    console.error('checkout error:', error);
    throw errorMessage;
  }
};
