import React, { useEffect, useState } from 'react';
import brandLogo from '../../assets/images/logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import OtpInput from 'react-otp-input';
import { sendOtp, signIn } from '../login/loginApi';
import { message } from 'antd';
import axios from 'axios';
import AppConfig from '../../config';
import {
  setUserId,
  setUserdetails,
  updateAccounts
} from 'features/login/loginSlice';
import { getUserDetails } from 'features/profile-screen/userApi';
import './styles.scss';

const OtpScreen = (props) => {
  const [otp, setOtp] = useState('');
  const [timeLeft, setTimeLeft] = useState(120);
  const [timerStarted, setTimerStarted] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const Helper = require('../../utils/helper');
  const token = localStorage.getItem('jwtToken');

  useEffect(() => {
    if (otp.length >= 6) {
      verifyOtp();
    }
  }, [otp]);


  useEffect(() => {
    Helper.isTokenExpired(token);
  }, []);
  
  useEffect(() => {
    setTimerStarted(true);
    if (timerStarted && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (timeLeft === 0) {
      // Timer has completed
      setTimerStarted(false);
      // You can replace this with any action you want
    }
  }, [timerStarted, timeLeft]);
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  const resendOtp = async () => {
    if (location?.state?.isNewUser) {
      try {
        setTimeLeft(120);
        setTimerStarted(true);
        const response = await axios.post(
          AppConfig.apiUrl + '/create_temporary_user',
          location?.state?.newUserData?.new_user,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
      } catch (error) {
        setTimerStarted(false);
        console.error('Error signing in:', error.message);
      }
    } else {
      try {
        setTimeLeft(120);
        setTimerStarted(true);
        let response = await dispatch(sendOtp(location?.state?.userValue));
      } catch (error) {
        setTimerStarted(false);
        console.error('Error signing in:', error.message);
      }
    }
  };

  const verifyOtp = async () => {
    if (location?.state?.isNewUser) {
      try {
        setLoading(true);
        setButtonDisabled(false);
        const userData = {
          email: location?.state?.newUserData?.new_user?.email,
          otp: parseInt(otp, 10),
          new_user: location?.state?.newUserData?.new_user
        };

        const response = await axios.post(
          AppConfig.apiUrl + '/verify_signup_otp',
          userData,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        const token = response.data.data.token;

        localStorage.setItem('jwtToken', token);
        dispatch(setUserdetails(response?.data?.data));
        dispatch(setUserId(response?.data?.data?.user?._id));

        if (response && response.data.success) {
          const data = await getUserDetails();
          dispatch(updateAccounts(data?.accounts || []));
          if (data.success) {
            navigate(
              `/new-account?account_id=${response?.data?.data?.user?.account_ids[0]}`
            );
          }
        } else {
          message.error(response.data.message);
        }
      } catch (error) {
        message.error(error?.response?.data.message);
        setTimerStarted(false);
        setOtp('');
        setButtonDisabled(true);
        setLoading(false);
        console.error('An error occurred:', error);
      }
    } else {
      try {
        setLoading(true);
        setButtonDisabled(false);
        const otpValue = {
          email: location?.state?.userValue?.email,
          otp: otp
        };

        let response = await dispatch(signIn(otpValue));
        if (response?.payload?.success) {
          navigate('/accounts');
        } else {
          message.error(response?.error.message);
          setTimerStarted(false);
          setOtp('');
          setButtonDisabled(true);
          setLoading(false);
        }
      } catch (error) {
        setTimerStarted(false);
        setOtp('');
        setButtonDisabled(true);
        setLoading(false);
        console.log('error?.message', error.message);
      }
    }
  };

  return (
    <>
      <div className="login-wrapper">
        <div className="loginBlock">
          <div className="brandLogo">
            <img src={brandLogo} alt="Brand Logo" />
          </div>
          <form className="login-form" onSubmit={(e) => e.preventDefault()}>
            <div class="sign-title">Verification Code</div>
            <p className="mb-3">
              We have sent you an access code via your email{' '}
              {location?.state?.userValue?.email
                ? location?.state?.userValue?.email
                : location?.state?.newUserData?.new_user?.email}
            </p>
            <div className="inputflexBlock">
              <div class="otp-input-fields">
                <OtpInput
                  inputType="number"
                  value={otp}
                  onChange={(otp) => {
                    setButtonDisabled(otp.trim() === ''); // Disable button if OTP is empty
                    setOtp(otp);
                  }}
                  numInputs={6}
                  renderSeparator={<span> </span>}
                  inputStyle={{
                    width: '40px',
                    height: '40px',
                    marginRight: '10px'
                  }}
                  renderInput={(props) => (
                    <input
                      style={{
                        width: '40px',
                        height: '40px'
                      }}
                      {...props}
                    />
                  )}
                />
              </div>
            </div>
            <div className="my-3">
              <button
                disabled={buttonDisabled ? buttonDisabled : isLoading}
                type="submit"
                className="btn btn-sm btn-primary w-100"
                onClick={verifyOtp}
              >
                {isLoading ? 'Please wait...' : 'Submit'}
              </button>
            </div>
            <div className="text-center">
              <p>
                Didn’t receive the OTP ? {''}
                {timerStarted ? (
                  <span className="red">{formatTime(timeLeft)}</span>
                ) : (
                  <span
                    onClick={resendOtp}
                    className={'green'}
                    style={{ cursor: 'pointer' }}
                  >
                    Resend
                  </span>
                )}
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default OtpScreen;
