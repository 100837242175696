import React, { useState,useEffect } from 'react';
import brandLogo from '../../assets/images/logo.png';
import './styles.scss';
import axios from 'axios';
import AppConfig from '../../config';
import { useParams } from 'react-router-dom';

const Subscription = () => {
  const { accountId } = useParams();
  const [selectedPlan, setSelectedPlan] = useState('Monthly');
  const Helper = require('../../utils/helper');
  const token = localStorage.getItem('jwtToken');

  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
  };


  useEffect(() => {
    Helper.isTokenExpired(token);
  }, []);

  const _subscription = async () => {
    try {
      const plan = selectedPlan.toLowerCase();
      const token = localStorage.getItem('jwtToken');
      const response = await axios.post(
        `${AppConfig.apiUrl}/create-checkout-session`,
        {
          subscription: plan,
          customer_id: 'cus_Pu5W7cdUDb7K1M',
          account_id: accountId
        },
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json'
          }
        }
      );
      const url = response.data.url;
      window.location.replace(url);
    } catch (error) {
      console.error('Error initiating analysis:', error);
      throw error;
    }
  };

  return (
    <>
      <div className="login-wrapper">
        <div className="login-container">
          <div className="loginBlock">
            <div className="brandLogo">
              <img src={brandLogo} alt="Brand Logo" />
            </div>
            <div className="login-form subscriptionForm">
              <h3>Built to help you trade better</h3>
              <p>One simple plan with everything you need.</p>
              <div class="switches-container">
                <input
                  type="radio"
                  id="switchMonthly"
                  name="switchPlan"
                  value="Monthly"
                  checked={selectedPlan === 'Monthly'}
                  onChange={handlePlanChange}
                />
                <input
                  type="radio"
                  id="switchYearly"
                  name="switchPlan"
                  value="Yearly"
                  checked={selectedPlan === 'Yearly'}
                  onChange={handlePlanChange}
                />
                <label for="switchMonthly">Monthly</label>
                <label for="switchYearly">Yearly</label>
                <div class="switch-wrapper">
                  <div class="switch">
                    <div>Monthly</div>
                    <div>Yearly</div>
                  </div>
                </div>
              </div>
              <div className="Plan--details">
                <p>Plan details go here...</p>
              </div>
              <button
                onClick={_subscription}
                className="btn btn-primary btn-sm w-100"
              >
                Subscription
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscription;
