/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Header from '../Header/component';
import SubHeader from '../sub-header/component';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import {
  selectAccounts,
  updateAccounts,
  getnewAccountButtonDisabled,
  setnewAccountButtonDisabled,
  addAccounts,
  setDefaultAccount,
  getDefaultAccount
} from '../login/loginSlice';
import { AddCricle } from 'assets/svgIcons';
import { createNewAccount } from './accountApi';
import Loader from 'components/loader/loader';
import { checkPaymentStatus, deleteAccounts } from './accountApi';
import { Popconfirm, message } from 'antd';
import { getUserDetails } from 'features/login/loginApi';
import ChatbotWeb from '../../components/chatbot/ChatbotWeb';
import ChatbotMobile from '../../components/chatbot/ChatbotMobile';
import {
  setCurrentAccount,
  setCurrentLogin,
  clearChatMessage,
  sendChatMessageThunk
} from '../../components/chatbot/ChatSlice';
import { io } from 'socket.io-client';
import SubscriptionModal from '../../components/SubscriptionModal';

const Helper = require('../../utils/helper');
const URL = 'wss://api.fractalalpha.com/deals';

const FaAccounts = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accounts = useSelector(selectAccounts);
  const activeAccounts = accounts?.filter(
    (account) => account.status === 'active'
  );

  const DefaultAccount = useSelector(getDefaultAccount);
  const newAccountButtonDisabled = useSelector(getnewAccountButtonDisabled);
  const [loader, setLoader] = useState(false);
  const [IsNewUser, setNewUser] = useState(false);
  const [InitiateModal, setInitiateModal] = useState(true);
  const InitiateModaltoggle = () => setInitiateModal(!InitiateModaltoggle);
  const [paymentChecking, setPaymentChecking] = useState({});
  const [isPopconfirmOpen, setIsPopconfirmOpen] = useState(false);
  const token = localStorage.getItem('jwtToken');
  const [selectedAccount, setSelectedAccount] = useState('');
  const [subscribeAccount, setSubscribeAccount] = useState('');
  const [selectedLogin, setSelectedLogin] = useState('');
  const [paymentModal, setPaymentModal] = useState(false);

  useEffect(() => {
    const socket = io(URL, {
      query: { login: selectedLogin },
      reconnectionAttempts: 5, // Attempt to reconnect up to 5 times
      reconnectionDelay: 2000 // Delay between reconnection attempts
    });

    socket.on('connect', () => {
      console.log('WebSocket connected');
    });

    socket.on('disconnect', () => {
      console.log('WebSocket disconnected');
    });

    socket.on('error', (error) => {
      console.error('WebSocket error:', error);
    });

    socket.on('new_trade', (trade) => {
      if (trade) {
        console.log('WebSocket calling...', trade);
        dispatch(getUserDetails());
        handleTradeMessage();
      }
    });

    return () => {
      socket.close();
      console.log('WebSocket connection closed');
    };
  }, [selectedLogin]);

  const handleTradeMessage = async () => {
    try {
      await dispatch(
        sendChatMessageThunk({
          account_id: selectedAccount,
          login: selectedLogin,
          message: 'trade message',
          name: '',
          type: 'bot',
          key: 'trade'
        })
      ).unwrap();
    } catch (err) {
      console.log('Failed to handle trade message');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    Helper.isTokenExpired(token);
  }, []);

  useEffect(() => {
    setNewUser(Helper.checkIfNewUser(accounts));
  }, [accounts]);

  const fetchData = async () => {
    try {
      dispatch(getUserDetails());
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  useEffect(() => {
    if (activeAccounts?.length === 1 && !selectedAccount) {
      const singleAccount = activeAccounts[0];
      setSelectedLogin(singleAccount.login);
      dispatch(setCurrentAccount(singleAccount.account_id));
      dispatch(setCurrentLogin(singleAccount.login));
    }
  }, [activeAccounts]);

  const goToOverview = (accountId, login) => {
    if (accountId !== selectedAccount) {
      dispatch(setDefaultAccount(accountId));
      dispatch(setCurrentAccount(accountId));
      dispatch(setCurrentLogin(login));
      dispatch(clearChatMessage(true));
    }
    setSelectedAccount(accountId);
    navigate(`/overview/${accountId}`);
  };

  const goToAnalysis = (accountId, login) => {
    if (accountId !== selectedAccount) {
      dispatch(setDefaultAccount(accountId));
      dispatch(setCurrentAccount(accountId));
      dispatch(setCurrentLogin(login));
      dispatch(clearChatMessage(true));
    }
    setSelectedAccount(accountId);
    navigate(`/analysis/${accountId}`, { state: { analysisPage: true } });
  };

  const goToTrades = (accountId, login) => {
    if (accountId !== selectedAccount) {
      dispatch(setDefaultAccount(accountId));
      dispatch(setCurrentAccount(accountId));
      dispatch(setCurrentLogin(login));
      dispatch(clearChatMessage(true));
    }
    setSelectedAccount(accountId);
    navigate(`/trades/${accountId}`);
  };

  const goToJournal = (accountId, login) => {
    if (accountId !== selectedAccount) {
      dispatch(setDefaultAccount(accountId));
      dispatch(setCurrentAccount(accountId));
      dispatch(setCurrentLogin(login));
      dispatch(clearChatMessage(true));
    }
    setSelectedAccount(accountId);
    navigate(`/journal/${accountId}`, { state: { journal: true } });
  };

  useEffect(() => {
    if (DefaultAccount) {
      setSelectedAccount(DefaultAccount);
      const selectedAcc =
        activeAccounts &&
        activeAccounts.find((account) => account.account_id === DefaultAccount);
      if (selectedAcc) {
        setSelectedLogin(selectedAcc.login);
      }
    }
  }, [DefaultAccount, activeAccounts]);

  const addNewAccount = async () => {
    try {
      setLoader(true);
      dispatch(setnewAccountButtonDisabled(true));
      const response = await createNewAccount();
      if (response?.success) {
        dispatch(addAccounts(response?.new_account));
        dispatch(setnewAccountButtonDisabled(false));
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      dispatch(setnewAccountButtonDisabled(false));
      message.error('Please try again');
    }
  };

  const checkPayment = async (account_id) => {
    try {
      setPaymentChecking((prevState) => ({
        ...prevState,
        [account_id]: true
      }));
      const res = await checkPaymentStatus(account_id);

      if (res.status === 'succeeded') {
        navigate(`/analysis-success/account_id=${account_id}`);
        setPaymentChecking((prevState) => ({
          ...prevState,
          [account_id]: false
        }));
      } else {
        let obj = accounts?.find((item) => item?.account_id === account_id);
        let isLoginNotNull = obj && obj?.login !== null;
        if (isLoginNotNull) {
          navigate(`/new-account?account_id=${account_id}`, {
            state: { loginValue: obj?.login }
          });
          setPaymentChecking((prevState) => ({
            ...prevState,
            [account_id]: false
          }));
        } else {
          navigate(`/new-account?account_id=${account_id}`);
          setPaymentChecking((prevState) => ({
            ...prevState,
            [account_id]: false
          }));
        }
      }
    } catch (error) {
      setPaymentChecking((prevState) => ({
        ...prevState,
        [account_id]: false
      }));
    }
  };

  const confirm = async (id) => {
    try {
      setIsPopconfirmOpen(true);
      const res = await deleteAccounts(id);
      if (res?.success) {
        const data = accounts?.filter((item) => item.account_id !== id);
        dispatch(updateAccounts(data));
        setIsPopconfirmOpen(false);
        message.success(
          `Your pending ${id} account deleted successfully `,
          1.5
        );
      } else {
        setIsPopconfirmOpen(false);
        message.error(res.message, 4);
      }
    } catch (error) {
      setIsPopconfirmOpen(false);
      console.log('error.response.data.message', error);
      message.error(error, 4);
    }
  };

  const handleResubscribe = (accountId) => {
    setSubscribeAccount(accountId);
    setPaymentModal(true);
  };

  return (
    <div className="main_page">
      <SubscriptionModal
        paymentModal={paymentModal}
        setPaymentModal={setPaymentModal}
        accountId={subscribeAccount}
      />
      <div
        className="left_container"
        style={{ position: 'relative', minHeight: '100vh' }}
      >
        <Header />
        <SubHeader accounts={accounts} />
        {accounts?.length === 0 ? (
          <Loader />
        ) : (
          <>
            <div className="content-wrapper">
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="accounts-lists">
                    {accounts?.map((account) => {
                      if (account.status === 'active') {
                        return (
                          <div
                            className="accounts-list"
                            key={account.account_id}
                          >
                            <div className="card">
                              <div className="card-header">
                                <p className="FA-heading">
                                  <span className="green">Account # </span>
                                  {account.account_id}
                                </p>
                                <p className="heading-right">
                                  <span className="activecircle"></span> Active
                                </p>
                              </div>
                              <div className="card-content">
                                <div className="row">
                                  <div className="col-lg-12 mb-3">
                                    {/* <div className="d-flex flex-column justify-content-between h-100"> */}
                                    <div className="FA-details">
                                      <div className="FA-detail-list">
                                        <p className="widget-heading">
                                          Closed P/L
                                        </p>

                                        <div
                                          className={
                                            isNaN(
                                              account?.calculation?.closed_pl
                                            )
                                              ? 'green widget-subheading'
                                              : account?.calculation
                                                ?.closed_pl < 0
                                                ? 'red widget-subheading'
                                                : 'green widget-subheading'
                                          }
                                        >
                                          {isNaN(
                                            account?.calculation?.closed_pl
                                          ) ? (
                                            '----'
                                          ) : (
                                            <>
                                              {account?.calculation?.closed_pl <
                                                0
                                                ? '-'
                                                : ''}
                                              $
                                              {Math.abs(Math.round(
                                                account?.calculation
                                                  ?.closed_pl ?? 0
                                              )).toLocaleString()}
                                            </>
                                          )}
                                        </div>
                                      </div>

                                      <div className="FA-detail-list">
                                        <p className="widget-heading">
                                          Expectancy
                                        </p>
                                        <div className="widget-subheading">
                                          <div
                                            className={
                                              isNaN(
                                                account?.calculation?.expectancy
                                              )
                                                ? 'green widget-subheading'
                                                : account?.calculation
                                                  ?.expectancy < 0
                                                  ? 'red widget-subheading'
                                                  : 'green widget-subheading'
                                            }
                                          >
                                            {isNaN(
                                              account?.calculation?.expectancy
                                            )
                                              ? '----'
                                              : (account?.calculation
                                                ?.expectancy < 0
                                                ? '-'
                                                : '') + '$'}
                                            {isNaN(
                                              account?.calculation?.expectancy
                                            )
                                              ? '----'
                                              : Math.abs(Math.round(
                                                account?.calculation
                                                  ?.expectancy
                                              ))}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="FA-detail-list">
                                        <p className="widget-heading">
                                          Profit Factor
                                        </p>
                                        <div className="widget-subheading">
                                          <div
                                            className={
                                              isNaN(
                                                account?.calculation
                                                  ?.profit_factor
                                              )
                                                ? 'green widget-subheading'
                                                : account?.calculation
                                                  ?.profit_factor < 0
                                                  ? 'red widget-subheading'
                                                  : 'green widget-subheading'
                                            }
                                          >
                                            {isNaN(
                                              account?.calculation
                                                ?.profit_factor
                                            )
                                              ? '----'
                                              : account?.calculation
                                                ?.profit_factor < 0
                                                ? '-'
                                                : ''}{' '}
                                            {isNaN(
                                              account?.calculation
                                                ?.profit_factor
                                            )
                                              ? '----'
                                              : Math.abs(
                                                account?.calculation
                                                  ?.profit_factor
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="FA-detail-list">
                                        <p className="widget-heading">
                                          Win Rate
                                        </p>
                                        <div className="widget-subheading">
                                          <div
                                            className={
                                              isNaN(
                                                account?.calculation?.win_rate
                                              )
                                                ? 'green widget-subheading'
                                                : account?.calculation
                                                  ?.win_rate < 0
                                                  ? 'red widget-subheading'
                                                  : 'green widget-subheading'
                                            }
                                          >
                                            {isNaN(
                                              account?.calculation?.win_rate
                                            )
                                              ? '----'
                                              : `${account?.calculation?.win_rate}%`}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div>
                                        <p className="widget-heading">
                                          Consistency
                                        </p>
                                        <div className="consistency">
                                          <div className="animated-progress progress">
                                            <div
                                              className="progress-bar bg-danger"
                                              style={{
                                                width: `${account?.calculation?.consistency}%`
                                              }}
                                            ></div>
                                          </div>
                                          <div className="consistency-value">
                                            <div className="con-value">
                                              0-30%
                                            </div>
                                            <div className="con-value">
                                              30-80%
                                            </div>
                                            <div className="con-value">
                                              80-100%
                                            </div>
                                          </div>
                                        </div>
                                      </div> */}
                                    {/* </div> */}
                                  </div>
                                  <div className="col-lg-12">
                                    <div className="status-btns">
                                      <button
                                        key={account.account_id}
                                        className="btn btn-primary btn-xs"
                                        onClick={() =>
                                          goToOverview(
                                            account.account_id,
                                            account.login
                                          )
                                        }
                                      >
                                        Overview
                                      </button>

                                      <button
                                        className="btn btn-primary btn-xs"
                                        onClick={() =>
                                          goToAnalysis(
                                            account.account_id,
                                            account.login
                                          )
                                        }
                                      >
                                        Analysis
                                      </button>
                                      <button
                                        className="btn btn-primary btn-xs"
                                        onClick={() => {
                                          goToTrades(
                                            account.account_id,
                                            account.login
                                          );
                                        }}
                                      >
                                        Trades
                                      </button>
                                      <button
                                        className="btn btn-primary btn-xs"
                                        onClick={() =>
                                          goToJournal(
                                            account.account_id,
                                            account.login
                                          )
                                        }
                                      >
                                        Journal
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      } else if (account.status === 'pending') {
                        return (
                          <div
                            className="accounts-list"
                            key={account.account_id}
                          >
                            <div className="card">
                              <div className="card-header">
                                <p className="FA-heading">
                                  <span className="yellow">Account # </span>
                                  {account.account_id}
                                </p>
                                <p className="heading-right">
                                  <div className="ai-analysis"></div> Pending
                                  Analysis
                                </p>
                              </div>
                              <div className="card-content">
                                <div className="row">
                                  <div className="col-lg-6">
                                    {/* <div className="d-flex flex-column justify-content-between h-100"> */}
                                    <div className="FA-details">
                                      <div className="FA-detail-list">
                                        <p className="widget-heading">
                                          Closed P/L
                                        </p>
                                        <div className="green widget-subheading">
                                          $0
                                        </div>
                                      </div>
                                      <div className="FA-detail-list">
                                        <p className="widget-heading">
                                          Expectancy
                                        </p>
                                        <div className="widget-subheading">
                                          $0
                                        </div>
                                      </div>
                                      <div className="FA-detail-list">
                                        <p className="widget-heading">
                                          Profit Factor
                                        </p>
                                        <div className="widget-subheading">
                                          0
                                        </div>
                                      </div>
                                      <div className="FA-detail-list">
                                        <p className="widget-heading">
                                          Win Rate
                                        </p>
                                        <div className="widget-subheading">
                                          0%
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="status-btns">
                                        <button
                                          className="btn btn-primary btn-xs"
                                          onClick={() => {
                                            checkPayment(account.account_id);
                                          }}
                                          disabled={
                                            paymentChecking[account.account_id]
                                          }
                                        >
                                          {paymentChecking[account.account_id]
                                            ? 'Please wait...'
                                            : 'Initiate Analysis'}
                                        </button>

                                        <Popconfirm
                                          title="Delete your pending account"
                                          description={`Are you sure to delete this account ${account.account_id}`}
                                          onConfirm={() =>
                                            confirm(account.account_id)
                                          }
                                          showCancel={true}
                                          okText="Yes"
                                          okType="danger"
                                          cancelText="Cancel"
                                          cancelButtonProps={{
                                            disabled: isPopconfirmOpen
                                          }}
                                        >
                                          <button
                                            disabled={isPopconfirmOpen}
                                            className="btn btn-danger btn-xs"
                                          >
                                            Delete
                                          </button>
                                        </Popconfirm>
                                      </div>
                                    </div>
                                    {/* <div>
                                        <p className="widget-heading">
                                          Consistency
                                        </p>
                                        <div className="consistency">
                                          <div className="animated-progress">
                                            <div
                                              className="progress-bar bg-danger"
                                              style={{ width: '80%' }}
                                            ></div>
                                          </div>
                                          <div className="consistency-value">
                                            <div className="con-value">
                                              0-30%
                                            </div>
                                            <div className="con-value">
                                              30-80%
                                            </div>
                                            <div className="con-value">
                                              80-100%
                                            </div>
                                          </div>
                                        </div>
                                      </div> */}
                                    {/* </div> */}
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="Initiate-text">
                                      Analysis is required in order to make
                                      account active. This will determine the
                                      ideal trading criterias based off of past
                                      trades.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      } else if (account.status === 'inactive') {
                        return (
                          <div
                            className="accounts-list"
                            key={account.account_id}
                          >
                            <div className="card">
                              <div className="card-header">
                                <p className="FA-heading">
                                  <span className="red">Account # </span>
                                  {account.account_id}
                                </p>
                                <p className="heading-right">
                                  <div className="inactive"></div> Inactive
                                </p>
                              </div>
                              <div className="card-content">
                                <div className="row">
                                  <div className="col-lg-12 mb-3">
                                    <div className="FA-details">
                                      <div className="FA-detail-list">
                                        <p className="widget-heading">
                                          Closed P/L
                                        </p>

                                        <div
                                          className={
                                            isNaN(
                                              account?.calculation?.closed_pl
                                            )
                                              ? 'green widget-subheading'
                                              : account?.calculation
                                                ?.closed_pl < 0
                                                ? 'red widget-subheading'
                                                : 'green widget-subheading'
                                          }
                                        >
                                          {isNaN(
                                            account?.calculation?.closed_pl
                                          ) ? (
                                            '----'
                                          ) : (
                                            <>
                                              {account?.calculation?.closed_pl <
                                                0
                                                ? '-'
                                                : ''}
                                              $
                                              {Math.abs(
                                                account?.calculation
                                                  ?.closed_pl ?? 0
                                              ).toLocaleString()}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      <div className="FA-detail-list">
                                        <p className="widget-heading">
                                          Expectancy
                                        </p>
                                        <div className="widget-subheading">
                                          <div
                                            className={
                                              isNaN(
                                                account?.calculation?.expectancy
                                              )
                                                ? 'green widget-subheading'
                                                : account?.calculation
                                                  ?.expectancy < 0
                                                  ? 'red widget-subheading'
                                                  : 'green widget-subheading'
                                            }
                                          >
                                            {isNaN(
                                              account?.calculation?.expectancy
                                            )
                                              ? '----'
                                              : (account?.calculation
                                                ?.expectancy < 0
                                                ? '-'
                                                : '') + '$'}
                                            {isNaN(
                                              account?.calculation?.expectancy
                                            )
                                              ? '----'
                                              : Math.abs(
                                                account?.calculation
                                                  ?.expectancy
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="FA-detail-list">
                                        <p className="widget-heading">
                                          Profit Factor
                                        </p>
                                        <div className="widget-subheading">
                                          <div
                                            className={
                                              isNaN(
                                                account?.calculation
                                                  ?.profit_factor
                                              )
                                                ? 'green widget-subheading'
                                                : account?.calculation
                                                  ?.profit_factor < 0
                                                  ? 'red widget-subheading'
                                                  : 'green widget-subheading'
                                            }
                                          >
                                            {isNaN(
                                              account?.calculation
                                                ?.profit_factor
                                            )
                                              ? '----'
                                              : account?.calculation
                                                ?.profit_factor < 0
                                                ? '-'
                                                : ''}{' '}
                                            {isNaN(
                                              account?.calculation
                                                ?.profit_factor
                                            )
                                              ? '----'
                                              : Math.abs(
                                                account?.calculation
                                                  ?.profit_factor
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="FA-detail-list">
                                        <p className="widget-heading">
                                          Win Rate
                                        </p>
                                        <div className="widget-subheading">
                                          <div
                                            className={
                                              isNaN(
                                                account?.calculation?.win_rate
                                              )
                                                ? 'green widget-subheading'
                                                : account?.calculation
                                                  ?.win_rate < 0
                                                  ? 'red widget-subheading'
                                                  : 'green widget-subheading'
                                            }
                                          >
                                            {isNaN(
                                              account?.calculation?.win_rate
                                            )
                                              ? '----'
                                              : `${account?.calculation?.win_rate}%`}
                                          </div>
                                        </div>
                                      </div>
                                      <div style={{ marginLeft: 'auto' }}>
                                        <button
                                          key={account.account_id}
                                          className="btn btn-primary btn-xs subscribe-btn"
                                          onClick={() =>
                                            handleResubscribe(
                                              account.account_id
                                            )
                                          }
                                        >
                                          Resubscribe
                                        </button>
                                      </div>
                                    </div>
                                    {/* <div className="Alert-box">
                                        <strong>Alert:</strong> This account is
                                        currently inactive due to a past due
                                        balance.
                                        <a
                                          className="green"
                                          style={{ textDecoration: 'none' }}
                                        >
                                          {' '}
                                          Resolve billing issue
                                        </a>
                                      </div> */}
                                    {/* <div>
                                      <p className="widget-heading">
                                        Consistency
                                      </p>
                                      <div className="consistency">
                                        <div className="animated-progress progress">
                                          <div
                                            className="progress-bar bg-danger"
                                            style={{ width: '80%' }}
                                          ></div>
                                        </div>
                                        <div className="consistency-value">
                                          <div className="con-value">0-30%</div>
                                          <div className="con-value">
                                            30-80%
                                          </div>
                                          <div className="con-value">
                                            80-100%
                                          </div>
                                        </div>
                                      </div>
                                    </div> */}
                                    {/* </div> */}
                                  </div>
                                  <div className="col-lg-12">
                                    <div>
                                      <div className="status-btns">
                                        <button className="btn btn-primary btn-xs disabled">
                                          Overview
                                        </button>
                                        <button className="btn btn-primary btn-xs disabled">
                                          Analysis
                                        </button>
                                        <button className="btn btn-primary btn-xs disabled">
                                          Trades
                                        </button>
                                        <button className="btn btn-primary btn-xs disabled">
                                          Journal
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                    <div className="text-center">
                      {!loader ? (
                        <button
                          className="btn btn-secondary gap-1"
                          onClick={addNewAccount}
                          disabled={newAccountButtonDisabled}
                        >
                          <AddCricle /> {'New Account'}
                        </button>
                      ) : (
                        <Loader size="small" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <ChatbotMobile
          selectedLogin={selectedLogin}
          activeAccounts={activeAccounts}
          showDropdown={true}
        />
      </div>
      <div className="right_container">
        <ChatbotWeb
          from={'account'}
          selectedLogin={selectedLogin}
          activeAccounts={activeAccounts}
        />
      </div>
    </div>
  );
};

export default FaAccounts;
