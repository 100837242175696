import axios from 'axios';
import AppConfig from '../../config';

export const getUserAccounts = async (userId) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      AppConfig.apiUrl + `user/account_ids?user_id=${userId}`,
      {},
      {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    console.error('Error during sign in:', error);
    throw errorMessage;
  }
};

export const createNewAccount = async () => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.put(
      `${AppConfig.apiUrl}/new_FAaccount`,

      {},
      {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    console.error('Error during sign in:', error);
    throw errorMessage;
  }
};

export const checkPaymentStatus = async (account_id) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.get(AppConfig.apiUrl + '/payment-status', {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json'
      },
      params: {
        account_id: account_id
      }
    });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message;

    console.error('Error during sign in:', error);

    throw errorMessage;
  }
};

export const deleteAccounts = async (account_id) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.delete(
      AppConfig.apiUrl + `/delete_fa-account`,
      {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json'
        },
        params: {
          account_id: account_id // Pass account_id as a parameter
        }
      }
    );

    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    console.error('Error during account deletion:', error);
    throw errorMessage;
  }
};

export const sendChatMessage = async (
  account_id,
  login,
  message,
  name,
  type,
  key
) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const response = await axios.post(
      `${AppConfig.apiUrl}/chat`,
      { account_id, login, message, name, type, key },
      {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    console.error('Error during sign in:', error);
    throw errorMessage;
  }
};
