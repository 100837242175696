import React, { useState,useEffect } from 'react';
import { useField } from 'formik';
import Select from 'react-select';
import moment from 'moment-timezone';

const TimeZoneSelect = ({ ...props }) => {
  const [field, , helpers] = useField(props.name);
  const [options] = useState(() => 
  moment.tz.names().map((tz) => ({
    value: tz,
    label: `(GMT${moment.tz(tz).format('Z')}) ${tz}`,
  }))
);
const [selectedOption, setSelectedOption] = useState(null);


  useEffect(() => {
    // Set the system timezone once when the component mounts
    const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const defaultOption = options.find((option) => option.value === systemTimeZone);

    if (defaultOption) {
      setSelectedOption(defaultOption); // Set initial state for dropdown
      helpers.setValue(defaultOption.value); // Update Formik field value
    }
  }, [helpers, options]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    helpers.setValue(selectedOption ? selectedOption.value : '');
  };

  return (
    <>
      <Select
        {...field}
        {...props}
        value={selectedOption}
        onChange={handleChange}
        options={options}
        isClearable
      />
    </>
  );
};

export default TimeZoneSelect;
